import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styleSheet = {
	label: {
		fontSize: 12
	},
	spinner: {
		color: 'rgb(154, 208, 57) !important'
	},
};

const Loader = ({ classes }) => {
	return (
		<div style={{ marginTop: -4 }}>
			<CircularProgress size={40}
				className={classes.spinner}
			/>
		</div>
	);
};

Loader.propTypes = {
	classes: PropTypes.object.isRequired,
};

Loader.defaultProps = {
	theme: 'accent',
	label: 'Loading...'
}

export default withStyles(styleSheet)(Loader);
