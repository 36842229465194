import React from 'react';
import SurveyForm from '../components/SurveyForm';

const Survey = (props) => {
	return (
		<main className='bg-dark waitlist-page-main'>
			<section className='bg-circles flex-center' style={{ minHeight: 'calc(100vh - 80px)', padding: '0 0.5rem' }}>
				<SurveyForm version={props.location.version} />
			</section>
		</main>
	);
}

export default Survey;