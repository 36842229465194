import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import HALogo from './assets/logo';


const Footer = (props) => {
	return (
		<footer className={`bg-circles color-secondary`}>
			{
				(window.location.pathname === '/' || window.location.pathname === '/hello' || window.location.pathname === '/holla')
				&&
				<div className={'footer-icons margin-auto mw-1280 width-p100'}>
					<div className='flex-row-spacebetween'>
						<div className='ta-left'>
							<Link to={{ pathname: '/', version: props.version }}><HALogo /></Link>
						</div>

						<div className={'footer-icons-social'}>
							<a href="https://www.facebook.com/homeattlas" target="_blank" rel="noopener noreferrer"><div className='social-icon fb-icon'></div></a>
							<a href="https://www.instagram.com/homeattlas" target="_blank" rel="noopener noreferrer"><div className='social-icon ig-icon'></div></a>
							<a href="https://www.twitter.com/homeattlas" target="_blank" rel="noopener noreferrer"><div className='social-icon tw-icon'></div></a>
						</div>
					</div>

					<div className='flex-row-spacebetween footer-copyright'>
						<p className='paragraph-xs secondary'>
							&copy; 2019 All rights reserved.
						</p>
						<p className='footer-follow-us'>
							Follow us on social media, we are fun!
						</p>
					</div>

				</div>
			}
		</footer>
	)
}

export default withRouter(Footer);