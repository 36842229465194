import HomeA from './pages/HomeA';
import HomeB from './pages/HomeB';
import Survey from './pages/Survey'
import Waitlisted from './pages/Waitlisted'

const Routes = {
	HomeA,
	HomeB,
	Survey,
	Waitlisted
}

export default Routes;