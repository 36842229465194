import React from 'react';

export class Dropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: props.open
		}
	}

	toggleShowAnswer() {
		this.setState({ open: !this.state.open });
	}

	render() {
		const { header, body } = this.props;

		return (
			<li className={this.state.open ? 'dropdown-root-expanded' : 'dropdown-root-closed'}
				onClick={this.toggleShowAnswer.bind(this)}>
				<div className='dropdown-header'>
					<div className='dropdown-header-text' style={this.state.open ? { fontWeight: 600 } : {}}>{header}</div>
					<div className={this.state.open ? 'dropdown-arrow-container-expanded' : 'dropdown-arrow-container-closed'}
						onClick={this.toggleShowAnswer.bind(this)}>
						<div className={this.state.open ? 'dropdown-arrow-expanded' : 'dropdown-arrow-closed'}></div>
					</div>
				</div>

				<div className={this.state.open ? 'dropdown-body-show' : 'dropdown-body-hide'}>
					{body}
				</div>
			</li>
		)
	}
}