import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from './Routes';
import Header from './Header';
import Footer from './Footer';
import './App.css';

let getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

function App() {
  let route = getRandomInt(2);
  let bucket = localStorage.getItem('ha__waitlist_bkt');
  if (!bucket) {
    bucket = route === 0 ? 'A' : 'B'
    localStorage.setItem('ha__waitlist_bkt', bucket);
  }
  else {
    route = bucket === 'A' ? 0 : 1;
  }

  return (
    <Router>
      <div className="App">
        <Header version={bucket} />
        <Switch>
          <Route exact path="/hello" component={Routes.HomeA} />
          <Route exact path="/holla" component={Routes.HomeB} />
          <Route exact path="/early-access" component={Routes.Waitlisted} />
          <Route exact path="/post-signup/:id" component={Routes.Survey} />
          <Route component={route === 0 ? Routes.HomeA : Routes.HomeB} />
        </Switch>
        <Footer version={bucket} />
      </div>
    </Router>
  );
}

export default App;
