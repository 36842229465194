import React from 'react';
import WaitlistForm from '../components/WaitlistForm';

const Waitlisted = (props) => {
	return (
		<main className='bg-dark waitlist-page-main'>
			<section className='bg-circles flex-center' style={{ minHeight: 'calc(100vh - 80px)', padding: '0 0.5rem' }}>
				<WaitlistForm version={props.location.version} />
			</section>
		</main>
	);
}

export default Waitlisted;