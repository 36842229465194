import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import HALogo from './assets/logo';

const Header = (props) => {
	return (
		<nav className='nav-header flex-row-spacebetween'>
			<div>
				<Link to={{ pathname: '/', version: props.version }}><HALogo /></Link>
			</div>
			{
				(window.location.pathname === '/' || window.location.pathname === '/hello' || window.location.pathname === '/holla')
				&&
				<ul className='nav-btn-list'>
					<li>
						<Link className='cta-nav-btn btn-s' to={{ pathname: '/early-access', version: props.version }}>Get Early Access</Link>
					</li>
				</ul>
			}
		</nav>
	);
}

export default withRouter(Header);