import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { FIRESTORE_DB } from '../index'
import Loader from './Loader';


const styles = () => ({
	root: {
		color: '#354052',
	},
	underlineLogin: {
		'&:hover:not($disabled):not($focused):not($error):before': {
			borderBottom: '1px solid #49ACF4 !important',
		},
		'&:after': {
			borderBottom: '2px solid #49ACF4',
		},
	},
	underlineSignup: {
		'&:hover:not($disabled):not($focused):not($error):before': {
			borderBottom: '1px solid #9AD039 !important',
		},
		'&:after': {
			borderBottom: '2px solid #9AD039',
		},
	},
	disabled: {},
	focused: {},
	error: {
		color: 'red'
	},
});


class SurveyForm extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			agentFee: false,
			landlords: false,
			communicate: false,
			showing: false,
			tenants: false,
			testSession: false,
			isAgent: false,
			other: false,
			btnDisabled: false,
			loading: false,
			surveySuccess: false,
			surveyFailure: false,
			version: props.version,
			otherText: ''
		};
	}

	componentDidMount() {
		const { match } = this.props;
		const id = match.params.id;
		FIRESTORE_DB.collection("users").doc(id).get().then((doc) => {
			if (doc.exists) {
				const { name, uid } = doc.data();
				this.setState({
					name,
					uid
				})
			} else {
				console.log("No such document!");
				this.setState({
					fetchUserFailure: true
				});
			}
		}).catch((error) => {
			console.log("Error getting document:", error);
		});
	}

	_handleCheck(field) {
		this.setState({ [field]: !this.state[field], error: false })
	}

	_handleFieldChange(field, e) {
		let error = `${field}Error`;
		let errorLabel = `${error}Text`;
		let fieldText = `${field}Text`;

		if (this.state[error]) {
			this.setState({
				[error]: false,
				[errorLabel]: ''
			});
		}
		this.setState({
			[fieldText]: e.target.value
		});
	}

	_handleWaitlistClick() {
		this._validateUserInput();
	}

	_saveSurveyToDB(userObject) {
		console.log(userObject);
		FIRESTORE_DB.collection("users").doc(userObject.uid).update(
			userObject
		).then((docRef) => {
			console.log('Document successfully updated');
			this.setState({
				surveySuccess: true,
			});
		}).catch((error) => {
			console.error("Error updating document: ", error);
		});
	}

	_showErrorState(errorObject) {
		this.setState({
			btnDisabled: false,
			loading: false,
			surveyFailure: true,
			failureMessage: errorObject
		});
	}

	_validateUserInput() {
		const { agentFee, tenants, landlords, communicate, other, isAgent, showing, otherText, fetchUserFailure, uid, testSession } = this.state;
		if (!other && !agentFee && !tenants && !landlords && !communicate && !showing) {
			this.setState({
				error: true,
				errorText: 'Please select at least one option here',
			});
		}
		else if (other && otherText === '') {
			this.setState({
				otherError: true,
				otherErrorText: 'You left this empty, please fill it out',
			});
		}
		else if (fetchUserFailure) {
			this.setState({
				surveyFailure: true,
				failureMessage: {
					header: `Sorry we can't find you on the waitlist`,
					body: 'Can you try clicking the survey link from your email again?'
				}
			});
		}
		else {
			const challenges = [
				agentFee ? 'Agent Fee' : false,
				tenants ? 'Finding tenants' : false,
				landlords ? 'Difficult landlords' : false,
				showing ? 'Showing tenants a place' : false,
				communicate ? 'Communicating with renters' : false,
				other ? otherText : false,
			].filter((challenge) => challenge);

			const surveyResponses = {
				uid,
				isAgent,
				challenges,
				userTesting: testSession
			}
			this._saveSurveyToDB(surveyResponses);
		}
	}

	_resetForm() {
		this.setState({
			btnDisabled: true,
			loading: true,
			surveyFailure: false
		});
	}

	render() {
		const { classes } = this.props;
		const { agentFee, tenants, landlords, communicate, other, isAgent, showing, error, errorText } = this.state;
		const buttonStyle = {
			height: 48,
			marginTop: 24,
			fontWeight: 500
		}
		const paragraphStyle = { fontSize: '1rem', textAlign: 'left' }
		const renderSuccessStatus = () => {
			return (
				<div className='waitlist-form-root bg-white flex-column-center' style={{ backgroundPosition: '40% 70%' }}>
					<CheckCircle style={{ fontSize: '5rem', fill: '#05CD75' }} />

					<h2 className='section-header'>
						Survey complete
					</h2>
					<div>
						<p style={{ marginTop: 16, marginBottom: 32 }}>Thank you for completing the survey.</p>
						<p style={{ marginBottom: 32 }}>You will receive an email message or a text message if you are one of the winners of the N1000 prize.</p>
						<p style={{ marginBottom: 32 }}>Gracias!</p>
					</div>

					<Link to={{ pathname: '/' }}>
						<button type='button' className={`cta-main-btn btn-l waitlist-btn`}
							style={{ background: '#cfecfd', color: '#354052', height: 48, marginBottom: 8 }}>
							Return Home
						</button>
					</Link>
				</div>
			)
		}

		const renderHeader = () => {
			const { fetchUserFailure, surveyFailure, name } = this.state;
			if (surveyFailure) {
				if (fetchUserFailure) {
					return (
						<h2 className='form-header color-accent'>
							{`Oops! Unable to verify user`}
						</h2>
					);
				}
			}
			else {
				return (
					<h2 className='form-header color-accent'>
						{`Hello ${name || 'there'}, thank you for joining early access`}
					</h2>
				);
			}
		}

		const renderSurveyForm = () => {
			return (
				<form className='waitlist-form-root bg-white flex-column-center' style={{ backgroundPosition: '40% 70%', maxHeight: 760 }}>
					{renderHeader()}
					{
						this.state.surveyFailure
							?
							<p className='error' style={{ marginBottom: 24, fontSize: '1rem' }}>
								<b>{this.state.failureMessage.header || 'Oops! An error occurred.'} </b>
								<br />{this.state.failureMessage.body || `It's not you, it's us, please try signing up again`}
							</p>
							:
							<p style={{ marginBottom: 32, marginTop: 8 }}>Please complete the survey below and enter a chance to win N1000</p>
					}

					<div>
						<label className='ta-left color-secondary survey-label'>Are you full-time agent?</label>
						<div className='flex-row' style={{ marginTop: 8 }}>
							<input type='checkbox' checked={isAgent}
								onChange={this._handleCheck.bind(this, 'isAgent')} className='agent-check'></input>
							<p style={paragraphStyle}>&nbsp;Select this if you are an agent</p>
						</div>

						<label className='ta-left color-secondary survey-label'>What are the biggest challenges you face as an agent?</label>
						<span id='error-name-field' role='alert' style={{ color: 'red' }}>{error && errorText}</span>

						<div className='flex-row' style={{ marginTop: 8 }}>
							<input type='checkbox' checked={agentFee}
								onChange={this._handleCheck.bind(this, 'agentFee')} className='agent-check'></input>
							<p style={paragraphStyle}>&nbsp;Tenants agreeing to pay agent fee</p>
						</div>
						<div className='flex-row' style={{ marginTop: 4 }}>
							<input type='checkbox' checked={landlords}
								onChange={this._handleCheck.bind(this, 'landlords')} className='agent-check'></input>
							<p style={paragraphStyle}>&nbsp;Difficult landlords</p>
						</div>
						<div className='flex-row' style={{ marginTop: 4 }}>
							<input type='checkbox' checked={tenants}
								onChange={this._handleCheck.bind(this, 'tenants')} className='agent-check'></input>
							<p style={paragraphStyle}>&nbsp;Hard to find tenants</p>
						</div>
						<div className='flex-row' style={{ marginTop: 4 }}>
							<input type='checkbox' checked={communicate}
								onChange={this._handleCheck.bind(this, 'communicate')} className='agent-check'></input>
							<p style={paragraphStyle}>&nbsp;Communicating with tenants</p>
						</div>
						<div className='flex-row' style={{ marginTop: 4 }}>
							<input type='checkbox' checked={showing}
								onChange={this._handleCheck.bind(this, 'showing')} className='agent-check'></input>
							<p style={paragraphStyle}>&nbsp;Showing tenants an apartment</p>
						</div>
						<div className='flex-row' style={{ marginTop: 4 }}>
							<input type='checkbox' checked={other}
								onChange={this._handleCheck.bind(this, 'other')} className='agent-check'></input>
							<p style={paragraphStyle}>&nbsp;Other challenges</p>
						</div>
						{
							this.state.other &&
							<TextField
								id='your-name'
								type='text'
								placeholder='Please type other challenges here'
								style={{ marginTop: 12 }}
								error={this.state.otherError}
								value={this.state.otherText}
								onChange={this._handleFieldChange.bind(this, 'other')}
								fullWidth
								required
								InputProps={{
									classes: {
										root: classes.root,
										input: classes.underlineSignup,
										underline: classes.underlineSignup,
									},
								}}
							/>
						}
						<span id='error-other-field' role='alert' style={{ marginTop: 4, color: 'red' }}>{this.state.otherErrorText}</span>

						<label className='ta-left color-secondary survey-label'>Can we invite you for a test session?</label>
						<div className='flex-row' style={{ marginTop: 4 }}>
							<input type='checkbox' checked={this.state.testSession}
								onChange={this._handleCheck.bind(this, 'testSession')} className='agent-check'></input>
							<p style={{ fontSize: '1rem' }}>&nbsp;Selecting this means yes</p>
						</div>
					</div>

					<div className='flex-center' style={{ marginTop: 32 }}>
						<button type='button' className={`cta-main-btn btn-l waitlist-btn`} id='waitlist-btn'
							style={this.state.loading ? { ...{ background: '#e8f5d0' }, ...buttonStyle } : buttonStyle}
							onClick={this._handleWaitlistClick.bind(this)}
							disabled={this.state.btnDisabled}>
							{
								this.state.loading
									?
									<Loader />
									:
									<span>Submit Survey</span>
							}
						</button>
					</div>
				</form>
			)
		}

		const renderContent = () => {
			if (this.state.surveySuccess) {
				return (
					renderSuccessStatus()
				);
			}
			else if (this.state.user) {
				return renderSurveyForm();
			}
			else {
				return renderSurveyForm();
			}
		}

		return (
			renderContent()
		)
	}

}


export default withStyles(styles)(withRouter(SurveyForm));