export const AUTH_ERRORS = {
	CODEEXPIRED: `auth/code-expired`,
	EMAILTAKEN: `auth/email-already-in-use`,
	INVALIDEMAIL: `auth/invalid-email`,
	INVALIDPHONE: `auth/invalid-phone-number`,
	NETWORKUNAVAILABLE: `auth/network-request-failed`,
	TOKENEXPIRED: `auth/id-token-expired`,
	USERNOTFOUND: `auth/user-not-found`,
	WEAKPASSWORD: `auth/weak-password`
}

export const parseAuthErrors = (errorMessage) => {
	switch (errorMessage) {
		case AUTH_ERRORS.EMAILTAKEN:
			return { header: `Oopsie, that's a used email!`, body: `Are you sure you haven't signed up already?` };
		case AUTH_ERRORS.INVALIDEMAIL:
			return { header: `Err, that's an invalid email!`, body: `Please check the email you entered` };
		case AUTH_ERRORS.INVALIDPHONE:
			return { header: `Err, that's an invalid phone number!`, body: `Please check again` };
		case AUTH_ERRORS.NETWORKUNAVAILABLE:
			return { header: `Oh dear, looks like you are offline`, body: `Please check to confirm` };
		default:
			return { header: `Oh dear, an error occurred`, body: `It's not you, it's us` };
	}
}