import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from '../components/Dropdown';
import ListAndEarn from '../assets/list-and-earn.svg';
import PaidForListing from '../assets/get-paid-listing.svg';
import UploadListing from '../assets/upload-listing-dark.svg';
import VerifyListing from '../assets/verify-listing.svg';


class HomeB extends Component {
	componentDidMount() {
		if (this.$ref && window.location.hash.includes('#faqs')) {
			this.$ref.scrollIntoView({
				behaviour: 'smooth',
				block: 'start',
				inline: 'nearest',
			});
		}
	}

	render() {
		return (
			<main>
				<section className='bg-dark'>
					<header className='main-top bg-circles'>
						<div className='main-top-section section-parent'>
							<div className='section-child top-section-child-left'>
								<h1 className='main-top-header color-white'>Lis<span className='color-accent'>t</span> 'n' Earn	<sup className='trademark'>&#8482;</sup></h1>
								<div style={{ marginTop: 16 }}>
									<p className='main-top-subheader color-white'>
										Hello Agent, why wait till you find a tenant before you earn?
										What if we told you that you can earn on your listings even before you find a tenant?
									</p>
									<p className='main-top-subheader color-white'>Sign up today for early access</p>
								</div>
								<Link className='cta-main-btn btn-l' to="/early-access">Signup for Early Access</Link>
							</div>
							<div className='section-child top-section-child-right'>
								<img className='main-top-section-img' src={ListAndEarn} alt='List and Earn' />
							</div>
						</div>
					</header>
				</section>

				<section className='section-root bg-primarylight'>
					<h2 className='section-header'>How does this work?</h2>
					<div className='section-container'>
						<div className='section-child section-child-left'>
							<p className='section-subheader'>Step I: Upload listing from your phone</p>
							<p className='section-text'>
								Take 3, 5, 8 pictures of your listing; be it a two-bedroom apartment, a self-contained apartment or even a duplex.
								Upload these pictures and other information about the listing directly from your mobile phone.
							</p>
						</div>
						<div className='section-child section-child-right'>
							<img className='main-top-section-img' src={UploadListing} alt='Upload listing from your device' />
						</div>
					</div>
				</section>

				<section className='section-root bg-houses'>
					<div className='section-container'>
						<div className='section-child'>
							<img className='main-top-section-img' src={VerifyListing} alt='Verify the listing' />
						</div>
						<div className='section-child'>
							<p className='section-subheader'>Step 2: We verify your listing</p>
							<p className='section-text'>
								We verify the listing you uploaded, to be sure it's genuine. We will also value the quality of
								the listing upload you based on some criteria including but not limited
								to the quality of images, video and description.
							</p>
						</div>
					</div>
				</section>

				<section className='section-root bg-primarylight'>
					<div className='section-container'>
						<div className='section-child section-child-left'>
							<p className='section-subheader'>Step 3: Earn on each verified listing</p>
							<p className='section-text'>
								Verification and valuation complete! It's time to earn on your verified listing and you can now smile home to the bank.
							</p>
						</div>
						<div className='section-child section-child-right'>
							<img className='main-top-section-img' src={PaidForListing} alt='Get paid for each listing' />
						</div>
					</div>
				</section>

				<section className='bg-circles color-white'>
					<div className='section-container'>
						<div className='bg-dark section-middle-cta'>
							<p className='section-subheader' style={{}}>Stop paying to list. <br />Start earning on your listings</p>
							<Link className='cta-main-btn btn-l' style={{ marginBottom: 24, marginTop: 24 }} to="/early-access">Get Early Access Today</Link>
						</div>
					</div>
				</section>

				<div ref={ref => { this.$ref = ref; }} style={{ padding: 12, height: 4 }} />

				<section className='section-root bg-circles'>
					<h2 className='section-header' style={{ paddingTop: 0 }}>Frequently Asked Questions</h2>
					<ul className='section-container faq-root'>
						<Dropdown header={'How does this really work?'}
							body={`You have a listing, you upload your listing, we verify and value your listing then you earn for each verified listing.`} />

						<Dropdown header={'How do you value a listing?'}
							body={'We value every listing using some predefined criteria - including but not limited to number and quality of images, the quality of description etc.'} />

						<Dropdown header={'How much can I earn for each listing?'}
							body={`You can earn as much as N8,000 depending on the quality of content added when the listing is uploaded`}
						/>

						<Dropdown header={'How long does it take to get my earning after uploading a listing?'}
							body={`Once your listing is verified and valued, you will receive a notification that your earnings for that listing is available. 
							Verification can take from 3 days to a maximum of 2 weeks.`
							}
						/>

						<Dropdown header={'Who owns the listing after it is uploaded?'}
							body={`The listing is yours and will forever be yours. There is no transfer of ownership of the listing when you upload it.`}
						/>

						<Dropdown header={'What happens if someone uploads a similar listing?'}
							body={`During the verification stage, we will notify you if we notice your listing is a duplicate.`}
						/>

						<Dropdown header={`So I've uploaded my listing, what next?`}
							body={
								`Awesome! Once we verify and value your listing, we will work with you to find prospective tenants.
								 But here's the good part - whether we find the tenants or not, your earning is SURE.`}
						/>

						<Dropdown header={'Are you real estate agents?'}
							body={`No we are not. Think of us as a cute apartment listing service, seeking to make life better for us all 😊.`} />
					</ul>
				</section>

				<section className='bg-dark color-white'>
					<div className='bg-circles section-bottom-cta'>
						<p className='section-subheader flex'>Nothing to lose and all to earn</p>
						<Link className='cta-main-btn btn-l' style={{ marginBottom: 24, marginTop: 24 }} to="/early-access">Signup today</Link>
					</div>
				</section>
			</main>
		);
	}
}


export default HomeB;