import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import App from './App';
import './index.css';
//import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
	apiKey: "AIzaSyCcNEKYPGy-qpvyRsipV93Ug-FwiXbhfOQ",
	authDomain: "home-attlas-waitlist.firebaseapp.com",
	databaseURL: "https://home-attlas-waitlist.firebaseio.com",
	projectId: "home-attlas-waitlist",
	storageBucket: "",
	messagingSenderId: "546750492302",
	appId: "1:546750492302:web:19eaf3fc4a3257f2"
};
firebase.initializeApp(firebaseConfig);
export const FIRESTORE_DB = firebase.firestore();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
